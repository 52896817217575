import { AxiosInstance } from "axios"
import { useEffect, useState, useRef } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import {
  Box,
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react"
import { EquityPartnerNavigationBar, EquityPartnerDashboardSearchBar, Navigation} from "./ui"
import { HiOutlineFilter } from "react-icons/hi"
import { DealsComponent } from "../Deals"
import { getIsLoggedIn, getUser, useDevice} from "../../hooks"
import { NavOption, Deal, UserType, } from "../../types"
import { OfferComponent } from "../Offer"
import { IconButton } from "@chakra-ui/react"
import { BsSearch } from "react-icons/bs"
var ed = require('edit-distance');
var insert:any, remove:any, update:any;
insert = remove = function() { return 1; };
update = function(stringA:string, stringB:string) { return stringA !== stringB ? 1 : 0; };

const LeftPanelContainer = styled(Box)``
LeftPanelContainer.defaultProps = {
  width: 'calc(100% / 5)',
  height: 'calc(100%)',
  position: 'fixed',
  left: 0,
  top: "60px",
  // background: "#5856e7",
  background: '#fafbfb',
  overflowY: 'scroll',
  overflowX: 'hidden',
  borderRight: "1px solid #edebeb"
}

const LargeContainer = styled(Box)``
LargeContainer.defaultProps = {
  width: '80%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '20%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

const MobileContainer = styled(Box)``
MobileContainer.defaultProps = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '0',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

interface Props {
  currentNavOption: string
  axios: AxiosInstance
}

export const EquityPartnerDashboardPage = ({ currentNavOption, axios }: Props) => {
  const navigate = useNavigate()
  //main deals component
  const [deals, setDeals] = useState<Deal[]>([])
  //update deals from search and filter
  const [sortOption, setSortOption] = useState<string>("")
  const [sortOrder, setSortOrder] = useState<string>("asc")
  const [searchParam, setSearchParam] = useState<string[]>([""])
  const [stage, setStage] = useState<string>('')
  //deal offer page
  const params = useParams()
  const { id } = params
  const [deal, setDeal] = useState<Deal|null>(null)
  //mobile page
  const {isMobile} = useDevice()
  //pagination
  const containerRef = useRef(null);
  
  useEffect(() => {
    const isLoggedIn = getIsLoggedIn()
    if (isLoggedIn) {
      if(NavOption.CLIENT === currentNavOption) {
        fetchDealbyId(id)
      }
      else if(NavOption.DASHBOARD === currentNavOption) {
        fetchDeals(deals.length)
      }
      else{
        navigate(`/login`)
      }
    } else {
      navigate(`/login`)
    }
  },[])

  useEffect(() => {
    if(NavOption.CLIENT === currentNavOption) {
      fetchDealbyId(id)
    }
  }, [id])

  useEffect(() => {
    deals.length = 0
    fetchDeals(deals.length)
  },[sortOrder,sortOption,searchParam,stage])

  //compares the search text with deal content
  const findDistance = (deal:Deal) => {
    let distance: number[] = []
    let tempDistance: number = 1000;
    const dealContentArray: string[] = [];
    let _searchParam: string[] = searchParam;
    dealContentArray.push(deal.client.firstName)
    dealContentArray.push(deal.client.lastName)
    dealContentArray.sort()
    _searchParam.sort()
    dealContentArray.push(deal.company.name)
    for (let i = 0; i < _searchParam.length; i++) {
      distance.push(1000)
    }
    for (let i = 0; i < _searchParam.length; i++) {
      for (let k = 0; k < dealContentArray.length; k++) {
        tempDistance = ed.levenshtein(dealContentArray[k].toLowerCase(),_searchParam[i].toLowerCase(),insert,remove,update).distance
        //console.log(dealContentArray[k].toLowerCase(),searchParam[i].toLowerCase(),tempDistance)
        if (tempDistance < distance[i]) {
          distance[i] = tempDistance;
        }
      }
      tempDistance = 1000;
    }
    let output = 0;
    for (let i = 0; i < distance.length; i++) {
      output += distance[i]
    }
    //console.log(output)
    return output
  }
  //fetch deal for offer page
  const fetchDealbyId = async (id:any) => {
    try {
      const user = await getUser()
      if (user && user.type === UserType.DEAL_MANAGER) {
        const response = await axios.post(`/deal-manager/dealbyid`, {dealId: id })
        const { data } = response
        if (response.status === 200 && data?.success) {
          setDeal(data.deal)
        } else {
          navigate(`/login`)
        }
      } else {
        navigate(`/login`)
      }
    } catch(e) {
      navigate(`/login`)
    }
  }
  //fetch deal for main deal component
  const fetchDeals = async (skip:number) => {
    try {
      const user = await getUser()
      if (user && user.type === UserType.DEAL_MANAGER) {
        const response = await axios.post(`/deal-manager/scroll`, { dealManagerId: user.id, skip, take: 50, sortOption , sortOrder, searchParam, stage })
        const { data } = response
        if (response.status === 200 && data?.success) {
          const _deals: Deal[] = data.deals
          let __deals: Deal[] = [...deals, ..._deals]
          if(searchParam[0].length !== 0) {
            __deals.sort((a,b) => {
              return findDistance(a) - findDistance(b)
            })
          }
          setDeals(__deals)
        } else {
          navigate(`/login`)
        }
      } else {
        navigate(`/login`)
      }
    } catch(e) {
      navigate(`/login`)
    }
  }
  //pagination function to fetch more deals
  const handleScroll = () => {
    if (NavOption.DASHBOARD === currentNavOption){
      if (containerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
        const hasReachedBottom = scrollHeight - (scrollTop + clientHeight) <= 10;
        if (hasReachedBottom) {
          fetchDeals(deals.length)
        }
      }
    }
  }
    
const getComponent = () => {
  if (currentNavOption === NavOption.CLIENT) {
    if (!deal) {
      fetchDealbyId(id)
    }
    if (deal) {
      if (deal.id !== Number(id)){
        fetchDealbyId(id)
      }
      return (
        <OfferComponent
          axios={axios}
          company={deal.company}
          dealManager={deal.dealManager}
          offers={deal.offers}
          stage={deal.stage}
        />
      )}
    } else if (currentNavOption === NavOption.DASHBOARD) {
      if (isMobile) {
        return (
          <Stack>
            <EquityPartnerDashboardSearchBar 
              setSortOption={setSortOption} 
              setSortOrder={setSortOrder} 
              setSearchParam={setSearchParam} 
              setStage={setStage}
              isMobile={true}
            />
            <DealsComponent deals={deals} />
          </Stack>
        )
      }
      return (
        <Stack>
          <EquityPartnerDashboardSearchBar 
            setSortOption={setSortOption} 
            setSortOrder={setSortOrder} 
            setSearchParam={setSearchParam} 
            setStage={setStage}
            isMobile={false}
          />
          <DealsComponent deals={deals} /> 
        </Stack>
      )
    }
  }

  return (
    <>
      {isMobile ?
        (
          <Stack m={0} p={0}>
            <EquityPartnerNavigationBar />
            <MobileContainer
              onScroll={()=> {handleScroll()}}
              ref={containerRef}
            >
              { getComponent() }
            </MobileContainer>
          </Stack>
        ) : (
          <>
            <EquityPartnerNavigationBar />
            <LeftPanelContainer>
              <Text fontSize={"lg"} color="brand.300" fontWeight="bold" p={5} onClick={() => navigate(`/dashboard`)}>
                Deal Portal
              </Text>
            <Navigation />
            </LeftPanelContainer>
            <Stack h='100vh'>
              <LargeContainer 
                onScroll={()=> {handleScroll()}}
                ref={containerRef}>
                  { getComponent() }
              </LargeContainer>
            </Stack>
          </> 
        )
      }
    </>
  )
}