import axios from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Button, Stack, Text } from "@chakra-ui/react"
import { CopyrightFooter, EquityPartnerNavigationBar, NavigationBar } from "../Main/ui"
import { getUser, removeSessionCookie, useDevice, useUserContext } from "../../hooks"
import { Me, UserType } from "../../types"

export const AccountPage = () => {
  const navigate = useNavigate()
  const user = useUserContext()
  const [me, setMe] = useState<Me | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const { isMobile } = useDevice()
  
  useEffect(() => {
    const fetchMe = async () => {
      const _user = await getUser()
      if (_user) {
        setMe(_user)
      } else {
        if (isLoading) {
          const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/auth/me`, {}, { withCredentials: true })
          const { data } = res
          console.log(data.user)
          if (data.success) {
            setMe(data.user)
          }
          setIsLoading(false)
        }
      }
    }

    if (!user) {
      fetchMe()
      setIsLoading(true)
    } else {
      setMe(user)
    }
  }, [user, isLoading])

  const handleSignOut = async () => {
    try {
      // await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/logout`, {
      //   method: 'POST',
      //   credentials: "include"
      // })
      removeSessionCookie()
      navigate(`/login`)
    } catch(e) {
      navigate(`/login`)
    }
  }

  return (
    <Stack>
      {
        user?.type === UserType.CLIENT ? (
          <NavigationBar />
        ) : (
          <EquityPartnerNavigationBar />
        )
      }
      <Stack pt={isMobile?(20):(10)} pb={10} pl={isMobile?(5):(20)} pr={isMobile?(5):(20)} h="100vh" overflowY="scroll">
        <Text pb={10} fontSize="3xl" fontWeight="bold">
          My Account
        </Text>
        <Stack pb={10}>
          <Text fontSize={12} color="#9FAFD0">
            Name:
          </Text>
          <Text>
            { me ? `${me.firstName} ${me.lastName}` : "" }
          </Text>
          <Text pt={5} fontSize={12} color="#9FAFD0">
            Email:
          </Text>
          <Text>
            { me ? me.email : "" }
          </Text>
          {
            me && me.createdDate && (
              <Stack pb={10}>
                <Text pt={5} fontSize={12} color="#9FAFD0">
                  Inception Date
                </Text>
                <Text>
                  { me.createdDate.split("T")[0] }
                </Text>
              </Stack>
            )
          }
          <Button color="white" bg="brand.300" w={100} onClick={() => handleSignOut()}>
            Sign Out
          </Button>
        </Stack>
        <Box p={20}>
        <CopyrightFooter />  
        </Box>
      </Stack>
    </Stack>
  )
}