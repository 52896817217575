import axios from "axios"
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"
import { Box } from "@chakra-ui/react"
import { DashboardPage } from "./DashboardPage"
import { LoginPage } from "../Auth"
import { PrivacyPolicyPage, TermsPage } from "../Legal"
import { NavOption } from "../../types"
import { EquityPartnerDashboardPage } from "./EquityPartnerDashboardPage"
import { PortfolioPage } from "./PortfolioPage"
import { AccountPage } from "../Account"
import { ResourcesPage } from "../Resources"

export const Main = () => {
  const axiosInstance = axios.create({ baseURL: process.env.REACT_APP_SERVER_URL, withCredentials: true })

  return (
    <Box>
      <BrowserRouter>
        {/* <NavBar currentNavOption={NavOption.CURRENT_OFFER} /> */}
        <Routes>
          <Route path="/login" element={ <LoginPage isInitialSignUp={false} /> } />
          <Route path="/get-started" element={ <LoginPage isInitialSignUp /> } />
          <Route path="/privacy-policy" element={ <PrivacyPolicyPage /> } />
          <Route path="/terms" element={ <TermsPage /> } />
          <Route path="/portfolio" element={ <PortfolioPage axios={axiosInstance} /> } />
          <Route path="/deal/:id/offer" element={ <DashboardPage currentNavOption={NavOption.CURRENT_OFFER} axios={axiosInstance} /> } />
          <Route path="/deal/:id/documents" element={ <DashboardPage currentNavOption={NavOption.DOCUMENTS} axios={axiosInstance} /> } />
          <Route path="/deal/:id/resources" element={ <DashboardPage currentNavOption={NavOption.RESOURCES} axios={axiosInstance} /> } />
          <Route path="/deal/:id/tax" element={ <DashboardPage currentNavOption={NavOption.TAX_CALCULATION} axios={axiosInstance} /> } />
          {/* <Route path="/deal/:id/background" element={ <DashboardPage currentNavOption={NavOption.BACKGROUND_CHECK} axios={axiosInstance} /> } /> */}
          <Route path="/account" element={ <AccountPage /> } />
          <Route path="/resources" element={ <ResourcesPage axios={axiosInstance} /> } />
          <Route path="/dashboard" element={ <EquityPartnerDashboardPage currentNavOption={NavOption.DASHBOARD} axios={axiosInstance} /> } />
          <Route path="/client/:id" element={ <EquityPartnerDashboardPage currentNavOption={NavOption.CLIENT} axios={axiosInstance} /> } />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </BrowserRouter>
    </Box>
  )
}