import { Box, VStack, Text, Stack } from "@chakra-ui/react"
import { OfferStatus } from "../../../types"
import {
  Step,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
} from '@chakra-ui/react'
import { useDevice } from "../../../hooks"

interface Props {
  status: OfferStatus
}

export const OfferStatusBar = ({ status }: Props) => {
  const { isMobile } = useDevice()

  const steps = [
    { title: 'Intro', description: 'desc' },
    { title: 'ESO Review', description: 'desc' },
    { title: 'Offer Ready', description: 'desc' },
    { title: 'Pre Closing', description: 'desc' },
    { title: 'Contract Review', description: 'desc' },
    { title: 'Post Closing', description: 'desc' },
    { title: 'Fully Funded', description: 'desc' },
    { title: 'Liquidity', description: 'desc' },
    { title: 'Closed', description: 'desc' },
  ]

  const getIndexFromStep = () => {
    switch (status) {
      case OfferStatus.INTRO:
        return 0;
      case OfferStatus.ESO_REVIEW:
        return 1;
      case OfferStatus.OFFER_READY:
        return 2;
      case OfferStatus.PRE_CLOSING:
        return 3;
      case OfferStatus.CONTRACT_REVIEW:
        return 4;
      case OfferStatus.POST_CLOSING:
        return 5;
      case OfferStatus.FULLY_FUNDED:
        return 6;
      case OfferStatus.LIQUIDITY:
        return 7;
      case OfferStatus.CLOSED:
        return 8;
    }
  }

  return(
    <>
    {
      isMobile ? (
        <Box w="100%" pl={10} pr={10}>
          <Stepper index={getIndexFromStep()} colorScheme='green' orientation="vertical">
            {
              steps.map((step, index) => (
                <Step key={index}>
                  <StepIndicator>
                    <StepStatus
                      complete={<StepIcon />}
                      incomplete={<StepNumber />}
                      active={<StepNumber />}
                    />
                  </StepIndicator>
                  <Box flexShrink='0'>
                    <StepTitle>{step.title}</StepTitle>
                  </Box>
                  <StepSeparator />
                </Step>
              ))
            }
          </Stepper>
        </Box>
      ) : (
        <Box w="100%" pl={10} pr={10}>
          <Stepper index={getIndexFromStep()} colorScheme='green'>
            {
              steps.map((step, index) => (
                <Step key={index}>
                  <VStack>
                    <StepIndicator>
                      <StepStatus
                        complete={<StepIcon />}
                        incomplete={<StepNumber />}
                        active={<StepNumber />}
                      />
                    </StepIndicator>
                    <Box flexShrink='0'>
                      {
                        step.title.indexOf(' ') > 0 ? (
                          <Stack spacing={0} textAlign={"center"}>
                            <Text fontSize={'xs'}>{step.title.substring(0,step.title.indexOf(' '))}</Text>
                            <Text fontSize={'xs'}>{step.title.substring(step.title.indexOf(' '), step.title.length)}</Text>
                          </Stack>
                          ):(
                            <Stack spacing = {0} textAlign={"center"}>
                              <Text fontSize={'xs'} mb={'4'}>{step.title}</Text>
                            </Stack>
                        )
                      }
                    </Box>
                  </VStack>
                  <StepSeparator />
                </Step>
              ))
            }
          </Stepper>
         </Box>
        )
      }
    </>
    
  )



  // const getBgColorByStatus = (offerStatus: OfferStatus) => {
  //   if (offerStatus === status) {
  //     return "gray"
  //   }

  //   const offerStatusIndex = ALL_OFFER_STATUSES.indexOf(offerStatus)
  //   const currentStatusIndex =  ALL_OFFER_STATUSES.indexOf(status)
    
  //   return offerStatusIndex < currentStatusIndex ? "green.300" : "gray" 
  // }

  // const getFontColorByStatus = (offerStatus: OfferStatus) => {
  //   if (offerStatus === status) {
  //     return "brand.300"
  //   }

  //   const offerStatusIndex = ALL_OFFER_STATUSES.indexOf(offerStatus)
  //   const currentStatusIndex =  ALL_OFFER_STATUSES.indexOf(status)
    
  //   return offerStatusIndex < currentStatusIndex ? "brand.300" : "gray" 
  // }

  // const getIconByStatus = (offerStatus: OfferStatus) => {
  //   const offerStatusIndex = ALL_OFFER_STATUSES.indexOf(offerStatus)
  //   const currentStatusIndex =  ALL_OFFER_STATUSES.indexOf(status)

  //   if (offerStatusIndex < currentStatusIndex) {
  //     return (
  //       <Icon color="white" as={BsCheck} w={5} h={5} />
  //     )
  //   } else {
  //     return (
  //       <Text color="white">
  //         { offerStatusIndex + 1 }
  //       </Text>
  //     )
  //   }
  // }

  // return (
  //   <Box w="100%" pl={10}>
  //     <HStack spacing={0} w={20}>
  //       {
  //         ALL_OFFER_STATUSES.map(offerStatus => {
  //           return (
  //             <HStack spacing={0}>
  //               <Stack h="60px">
  //                 <HStack spacing={0}>
  //                   <Center key={offerStatus} w={45} h={45} bg={ getBgColorByStatus(offerStatus) } borderRadius={50}>
  //                     {
  //                       getIconByStatus(offerStatus)
  //                     }
  //                   </Center>
  //                   {
  //                     offerStatus !== ALL_OFFER_STATUSES[ALL_OFFER_STATUSES.length - 1] && (
  //                       <Box bg={getBgColorByStatus(offerStatus)} h={2} w={50} pl={-5}/>
  //                     )
  //                   }
  //                 </HStack>
  //                 <Text
  //                   fontSize={10}
  //                   w={50}
  //                   mr={10}
  //                   color={ getFontColorByStatus(offerStatus) }
  //                   textAlign="center"
  //                   fontWeight="bold"
  //                 >
  //                   { cleanseOfferStatusStr(offerStatus) }
  //                 </Text>
  //               </Stack>
  //             </HStack>
  //           )
  //         })
  //       }
  //     </HStack>
  //   </Box>
  // )
}
