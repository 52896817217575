export const INSTRUCTIONS_NDA = "Our Mutual Non-Disclosure Agreement will protect both your company's information as well as your own. At the same time, it prohibits the sharing of our offer contracts with anyone other than the professional advisors assisting you on this transaction."
export const INSTRUCTIONS_BC = "It is essential that you have a credit report clear of lawsuits and bankruptcies in order for us to finance your stock transaction. Please note that this form cannot be accepted unless it has your full legal name (first, middle, last), SSN, your address, and your birthdate. Also note that a transaction with ESO will not materially impact your credit rating except for creating a small notation that we ran a report. We typically use Experian for credit checks, so if you have placed a security freeze on your credit file with that bureau, please lift the freeze briefly and let us know when we can run the report (a very quick process)."
export const get409aProofIntstructions = (companyName: string): string => {
  return `Please provide a screenshot of your equity portal showing the current 409A Fair Market Value. If your company does not have an equity portal, an email trail from the company will suffice (recommended to reach out to Finance or HR - you can ask your ESO Equity Partner who other ${companyName} client’s have emailed in this situation).`
}
export const INSTRUCTIONS_OPTIONS_AND_SHARES = "If you have options: Please provide a screenshot of your equity portal showing your number of vested options, strike price, and grant date. If your company does not have an equity portal either an email (or file) from the company or a copy of your Stock Option Grant will suffice. \n If you have shares: Please provide a screenshot of your equity portal showing your number of vested/owned shares. Please also provide documentation of the original strike price of the shares and the 409A/FMV at the time of exercise."
export const INSTRUCTIONS_STOCK_OPTION_PLAN = "Please provide a copy of your Stock Option Plan (may have a different name, ex: Equity Incentive Plan)."
export const INSTRUCTIONS_OPTION_GRANT_DOCUMENTS = "Please provide a copy of your Stock Option Grant Agreement, Notice of Stock Option Grant and Stock Option Exercise Notice. Note the naming of these documents varies, feel free to ask your ESO Equity Partner if you have any questions."
export const INSTRUCTIONS_STOCK_CERTIFICATE = "Please provide a copy of your digital Stock Certificate for your shares. If no Stock Certificate is issued please provide either a screenshot of your equity portal or a copy of your Tax Form 3921. If none of these options are available please reach out to your ESO Equity Partner for assistance."

export const INSTRUCTIONS_ID_START = "Please submit 2 forms of valid photo ID (ideally Driver’s License and Passport)"
export const INSTRUCTIONS_ID_MID = "If you are not a US citizen, we also will need a permanent resident card or other appropriate immigration document."
export const INSTRUCTIONS_ID_END = "If your license does not show your current address as indicated on our Agreement, please also include a utility bill, DMV registration, etc. that does."