import { AxiosInstance } from "axios"
import { useState } from "react"
import { FaDollarSign, FaMoneyBillWave, FaPercentage } from "react-icons/fa"
import { GiPartyPopper } from "react-icons/gi"
import {
  Box,
  Button,
  Divider,
  HStack,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  VStack
} from "@chakra-ui/react"
import { EquityLabel, NoOfferLabel, OfferChart, OfferDetailsItem, OfferStatusBar, WhatsNextSection } from "./ui"
import { HypotheticalExitIllustrationComponent } from "./components"
import { INSTRUCTIONS_OFFER_START, INSTRUCTIONS_OFFER_MID, INSTRUCTIONS_OFFER_END } from "./constants"
import { Company, DealManager, Offer, OfferStatus } from "../../types"
import { IncentiveDeadlineDict } from "../../constants"
import { cleanseOfferStatusStr } from "../../utils"
import { useDevice } from "../../hooks"

interface Props {
  axios: AxiosInstance
  company: Company
  dealManager: DealManager
  offers: Offer[]
  stage: OfferStatus
  acceptOffer?: (offerNumber: number) => void
}

interface OfferDetailsProps {
  companyName: string
  exerciseCost: number | null
  desiredLiquidity: number
  pp: number
  lp: number
  numShares: number
}

const OfferDetails = ({ exerciseCost, desiredLiquidity, pp, lp }: OfferDetailsProps) => {
  return (
    <Stack pl={10}>
      <OfferDetailsItem
        label="Total Upfront Payment:"
        iconType={FaDollarSign}
        value={`$${(exerciseCost ?? 0 + desiredLiquidity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
        details={`Consists of an Exercise Payment of $${(exerciseCost ?? 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} and a liquidity payment of $${desiredLiquidity.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
      />
      <OfferDetailsItem
        label="Participation Percentage:"
        iconType={FaPercentage}
        value={`${(pp * 100).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%`}
        details="ESO Fund's perecentage of the equity"
      />
      <OfferDetailsItem
        label="Liquidation Preference:"
        iconType={FaMoneyBillWave}
        value={`$${lp.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
        details="ESO Fund's perecentage of the equity"
      />
    </Stack>
  )
}

export const OfferComponent = ({ axios, company, dealManager, offers, stage, acceptOffer }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isViewingOffer, setIsViewingOffer] = useState(true)
  const { isMobile } = useDevice()
  
  return (
    <Box>
      <>
        <Stack spacing={50}>
          <Stack>
            <Text fontSize="3xl" pl={10} pt={10}>
              Current Offer
            </Text>
            <HStack pl={10} pb={10}>
              <Text fontSize="lg">
                Current Stage:
              </Text>
              <Text fontSize="lg" color="green.300">
                { cleanseOfferStatusStr(stage) }
              </Text>
            </HStack>
            <OfferStatusBar status={stage} />
          </Stack>
          <Divider />
          {
            offers[currentIndex] && (
              <WhatsNextSection
                axios={axios}
                stage={stage}
                dealManager={dealManager}
                offerNumber={offers[currentIndex].offerNumber}
                acceptOffer={acceptOffer}
              />
            )
          }
          {
            offers.length ? (
              <>
                <Stack pl={10} pr={10} pb={10}>
                  <HStack pb={5}>
                    {
                      offers.length > 1 && (
                        offers.map((_, index) => {
                          return (
                              <Button 
                                color={ index === currentIndex ? "white" : "black"}
                                bg={ index === currentIndex ? "brand.100" : "transparent" }
                                onClick={() => setCurrentIndex(index)}>
                                  { `Offer #${index + 1}` }
                              </Button>
                          )
                        })
                      )
                    }
                    <Spacer />
                  </HStack>
                  {
                    offers[currentIndex].isAccepted === 1 && (
                      <HStack pb={10}>
                        <GiPartyPopper size={20} color="#3F5EA1" />
                        <Text fontSize="md" fontWeight="bold" color="brand.100">
                          Accepted!
                        </Text>
                        <GiPartyPopper size={20} color="#3F5EA1" />
                      </HStack>
                    )
                  }
                  <HStack w="100%">
                    <Text fontSize="xl">Equities</Text>
                    <Spacer />
                  </HStack>
                  <Box w="100%">
                    <TableContainer border="1px">
                      <Table variant="simple">
                        <Thead bg="brand.900">
                          <Tr>
                            <Th>Grant Description</Th>
                            <Th>Type</Th>
                            <Th>Number of Shares</Th>
                            <Th>Strike Price</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {
                            offers[currentIndex].equities.map(equity => {
                              console.log(equity)
                              return (
                                <EquityLabel equity={equity} />
                              )
                            })
                          }
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Stack>
                <Divider />
              </>
            ) : (
              <NoOfferLabel dealManager={dealManager} />
            )
          }
        </Stack>
        {
          offers.length > 0 && (
            <Stack pb={100} pt={10}>
              <Text fontSize="xl" pl={10}>Offer Details</Text>
              {
                offers[currentIndex].isIncentive === 1 && offers[currentIndex].incentiveType && (
                  <Stack pl={10}>
                    <HStack>
                      <Button
                        color={isViewingOffer ?  "white" : "brand.100"}
                        bg={isViewingOffer ?  "brand.100" : "transparent"}
                        variant={isViewingOffer ? "solid" : "outline"}
                        onClick={() => setIsViewingOffer(true)}
                      >
                        {`After ${IncentiveDeadlineDict[offers[currentIndex].incentiveType!]}`}
                      </Button>
                      <Button
                        color={isViewingOffer ?  "brand.100" : "white"}
                        bg={isViewingOffer ?  "transparent" : "brand.100"}
                        variant={isViewingOffer ?  "outline" : "solid"}
                        onClick={() => setIsViewingOffer(false)}
                      >
                        {`Before ${IncentiveDeadlineDict[offers[currentIndex].incentiveType!]}`}
                      </Button>
                      <Spacer />
                    </HStack>
                  </Stack>
                )
              }
              <OfferDetails
                companyName={company.name}
                exerciseCost={offers[currentIndex].exerciseCost}
                desiredLiquidity={offers[currentIndex].desiredLiquidity}
                pp={isViewingOffer ? offers[currentIndex].pp : offers[currentIndex].incentivePp ?? offers[currentIndex].pp}
                lp={isViewingOffer ? offers[currentIndex].lp : offers[currentIndex].incentiveLp ?? offers[currentIndex].lp}
                numShares={offers[currentIndex].equities.reduce((a, b) => { return a + b.amount }, 0)}
              />
              {
                isMobile ? (
                  <Text p={10}>
                    This financing is {' '} 
                  <Text fontWeight="bold" as="span">
                    non-recourse,
                  </Text>
                    {' '} so you are not required to personally guarantee these payments to ESO in the event the underlying stock is unsuccessful.
                  </Text>
                ) : (
                  <Text p={10} w="70%">
                    This financing is {' '} 
                  <Text fontWeight="bold" as="span">
                    non-recourse,
                  </Text>
                    {' '} so you are not required to personally guarantee these payments to ESO in the event the underlying stock is unsuccessful.
                  </Text>
                )
              }
              
              <Divider />
              <Text fontSize="xl" pt={10} pl={10}>Exit Illustration</Text>
              <Text w={isMobile?("100%"):("60%")} pl={10} pr={10}>
                The graph below illustrates the returns this offer would generate over a range of exit share prices. 
              </Text>
              <HStack>
                <Spacer />
                <Box w={isMobile ? ("100%"):("70%")} pt={5} pr={isMobile?("0"):("5")}>
                  <OfferChart
                    company={company}
                    offer={offers[currentIndex]}
                    pp={isViewingOffer ? offers[currentIndex].pp : offers[currentIndex].incentivePp ?? offers[currentIndex].pp}
                    lp={isViewingOffer ? offers[currentIndex].lp : offers[currentIndex].incentiveLp ?? offers[currentIndex].lp}
                  />
                </Box>
                <Spacer />
              </HStack>
              <Divider />
              <HypotheticalExitIllustrationComponent
                offer={offers[currentIndex]}
                pp={isViewingOffer ? offers[currentIndex].pp : offers[currentIndex].incentivePp ?? offers[currentIndex].pp}
                lp={isViewingOffer ? offers[currentIndex].lp : offers[currentIndex].incentiveLp ?? offers[currentIndex].lp}
              />
              <Divider p={10} />
              <VStack>
                <Text w={isMobile?("85%"):("70%")} pt={10} pb={5}>
                  { INSTRUCTIONS_OFFER_START }
                </Text>
                <Text w={isMobile?("85%"):("70%")} pb={5}>
                  { INSTRUCTIONS_OFFER_MID }
                </Text>
                <Text fontSize="large" fontWeight="bold" w={isMobile?("85%"):("70%")} pb={5}>
                  Optimization
                </Text>
                {
                  INSTRUCTIONS_OFFER_END.map(instructions => {
                    return (
                      <Text w={isMobile?("85%"):("70%")} pb={5}>
                        { instructions }
                      </Text>
                    )
                  })
                }
              </VStack>
            </Stack>
          )
        }
      </>
    </Box>
  )
}