import { Td, Tr } from "@chakra-ui/react"
import { Equity, EquityType } from "../../../types"

interface Props {
  equity: Equity
}

export const EquityLabel = ({ equity }: Props) => {
  const { name, type, amount, strikePrice } = equity
  const equityTypeStr = type === EquityType.SHARE ? type.replace(/(^\w|\s\w)/g, m => m.toUpperCase()) : type.toUpperCase()

  return (
    <Tr>
      <Td>{ name }</Td>
      <Td>{ equityTypeStr }</Td>
      <Td>{ amount.toLocaleString() }</Td>
      <Td>{ `$${strikePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` }</Td>
    </Tr>
  )
}
