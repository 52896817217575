import { AxiosInstance } from "axios"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Box, Button, HStack, Link, ListItem, OrderedList, Spacer, Text, UnorderedList, useToast } from "@chakra-ui/react"
import { AcceptOfferModal } from "./AcceptOfferModal"
import { DealManager, NavOption, OfferStatus } from "../../../types"
import { openInNewTab } from "../../../utils"

interface Props {
  axios: AxiosInstance
  dealManager: DealManager
  stage: OfferStatus
  offerNumber?: number
  acceptOffer?: (offerNumber: number) => void
}

export const WhatsNextSection = ({ axios, dealManager, stage, offerNumber, acceptOffer }: Props) => {
  const navigate = useNavigate()
  const toast = useToast()
  const [isHidden, setIsHidden] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isAcceptOfferModalOpen, setIsAcceptOfferModalOpen] = useState(false)
  
  const sendNda = async () => {
    const response = await axios.post(`/documents/nda`)
    const { data } = response
    if (data && data.success) {
      toast({
        title: `NDA successfully sent to your email! If you don't see it, please contact your equity partner.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    } else {
      toast({
        title: `Oops something went wrong while requesting for an NDA. In the meantime, please email ${dealManager.email} to request an NDA`,
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const sendBackgroundCheck = async () => {
    const response = await axios.post(`/documents/bc`)
    const { data } = response
    if (data && data.success) {
      toast({
        title: `Background Check successfully sent to your email! If you don't see it, please contact your equity partner.`,
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    } else {
      toast({
        title: `Oops something went wrong while requesting for Background Check. In the meantime, please email ${dealManager.email} to request a Background Check`,
        status: "error",
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const handleAcceptOfferModalClose = () => {
    setIsAcceptOfferModalOpen(false)
    setIsLoading(false)
  }

  const handleAcceptOfferClick = () => {
    setIsAcceptOfferModalOpen(true)
    setIsLoading(true)
  }

  const handleConfirmAcceptOffer = async (offerNumber: number) => {
    setIsAcceptOfferModalOpen(false)
    if (acceptOffer) {
      await acceptOffer(offerNumber)
      setIsLoading(false)
      toast({
        title: `Offer Accepted!`,
        status: "success",
        duration: 3000,
        isClosable: true,
      })
    }
  }

  const getWhatsNextInstructions = () => {
    switch(stage) {
      case OfferStatus.INTRO:
        return (
          <>
            <Text pb={5} fontWeight="bold">What ESO needs from you to get started:</Text>
            <OrderedList>
              <ListItem>
                <Link color="brand.100" onClick={() => openInNewTab(dealManager.calendly)}>
                    Set up an intro call
                </Link>
                {' '} with your Equity Partner
              </ListItem>
              <ListItem>
                Review and sign {' '}
                <Link color="brand.100" onClick={() => sendNda()}>
                  the mutual NDA
                </Link>
              </ListItem>
              <ListItem>
                If you want to get ahead you can submit confirmation of your equity info (please see {' '} 
                <Link color="brand.100" onClick={() => navigate(`/${NavOption.DOCUMENTS}`)}>
                  Documents 
                </Link>
                {' '} section for more on what is needed).
              </ListItem>
            </OrderedList>
          </>
        )
      case OfferStatus.ESO_REVIEW:
        return (
          <>
            <Text pb={5} fontWeight="bold">What ESO needs from you to provide an offer**:</Text>
            <OrderedList>
              <ListItem>
                <Text>
                  If this is an option exercise, ESO needs confirmation of the following:
                </Text>
                <UnorderedList>
                  <ListItem>Number of options</ListItem>
                  <ListItem>Strike Price (for each grant)</ListItem>
                  <ListItem>Current Fair Market Value aka 409a</ListItem>
                </UnorderedList>
              </ListItem>
              <ListItem>
                <Text>
                  If this is share liquidity, ESO needs confirmation of the following:
                </Text>
                <UnorderedList>
                  <ListItem>Number of shares</ListItem>
                  <ListItem>Current Fair Market Value aka 409a</ListItem>
                </UnorderedList>
              </ListItem>
            </OrderedList>
            <Text pt={5} fontSize={14}>
              **If your company uses Carta please follow this {' '}
              <Link color="brand.100" onClick={() => openInNewTab("https://support.carta.com/s/article/share-your-portfolio")}>
                link
              </Link>
              {' '}to add your Equity Partner to your Carta account
            </Text>
          </>
        )
      case OfferStatus.OFFER_READY:
        return (
          <>
            <Text pb={5} fontWeight="bold">What ESO needs from you to move forward:</Text>
            <OrderedList pb={5}>
              <ListItem>Review your offer, feel free to ask questions via email or phone!</ListItem>
              <ListItem>Once ready, accept terms using the "Accept Offer" button below or by notifying your Equity Partner via email.</ListItem>
            </OrderedList>
            {
              acceptOffer && (
                <Button bg="green.400" color="white" onClick={handleAcceptOfferClick} isLoading={isLoading}>
                  Accept Offer
                </Button>
              )
            }
          </>
        )
      case OfferStatus.PRE_CLOSING:
        return (
          <>
            <Text pb={5} fontWeight="bold">What ESO needs from you prior to sending a contract:</Text>
            <OrderedList pb={5}>
              <ListItem>
                Fill out and submit {' '}
                <Link color="brand.100" onClick={() => sendBackgroundCheck()}>
                  the Background Check Permission Form
                </Link>
              </ListItem>
              <ListItem>Submit documents needed to close:</ListItem>
              <UnorderedList>
                <ListItem>Copy of your Stock Option Grant</ListItem>
                <ListItem>Copy of your Stock Option Plan</ListItem>
              </UnorderedList>
            </OrderedList>
            <Text>If you have any questions on the above documents please reach out to your Equity Partner via email</Text>
          </>
        )
      case OfferStatus.CONTRACT_REVIEW:
        return (
          <>
            <Text pb={5} fontWeight="bold">What ESO needs from you to close:</Text>
            <OrderedList pb={5}>
              <ListItem>
                Review the Contract (you should have received an email from DocuSign, if not please notify your Equity Partner).
              </ListItem>
              <ListItem>Once ready to close:</ListItem>
              <UnorderedList>
                <ListItem>
                  Notarize the Limited Power of Attorney (you should have received an email with a pdf, if not please notify your Equity Partner). Feel free to notarize in person or online via {' '}
                  <Link color="brand.100" onClick={() => openInNewTab("https://notarize.com")}>
                    notarize.com
                  </Link>
                </ListItem>
                <ListItem>Submit 2 forms of valid photo ID (i.e. Driver’s License, Passport, Visa etc)</ListItem>
                <ListItem>
                  Sign the contract on DocuSign.	If you are married, your spouse will receive an email from DocuSign following your signature. They will need to sign as well.
                </ListItem>
                <ListItem>
                  <Link color="brand.100" onClick={() => openInNewTab(dealManager.calendly)}>
                    Schedule your Closing meeting
                  </Link>
                  {' '} (which will take place via Zoom)
                </ListItem>
              </UnorderedList>
            </OrderedList>
          </>
        )
      case OfferStatus.POST_CLOSING:
        return (
          <>
            <Text pb={5} fontWeight="bold">What ESO needs from you prior to sending the liquidity payment:</Text>
            <OrderedList pb={5}>
              <ListItem>Confirmation of Exercise within 3 business days of closing.</ListItem>
              <ListItem>Confirmation of Stock Certificate (ESO understands that timing is subject to the company)</ListItem>
            </OrderedList>
          </>
        )
      default:
        return (
          <>
            <Text pb={5} fontWeight="bold">What ESO needs from you to get started:</Text>
            <OrderedList>
              <ListItem>
                <Link color="brand.100" onClick={() => openInNewTab(dealManager.calendly)}>
                    Set up an intro call
                </Link>
                {' '} with your Equity Partner
              </ListItem>
              <ListItem>
                Review and sign {' '}
                <Link color="brand.100" onClick={() => sendNda()}>
                  the mutual NDA
                </Link>
              </ListItem>
              <ListItem>
                If you want to get ahead you can submit confirmation of your equity info (please see {' '} 
                <Link color="brand.100" onClick={() => navigate(`/${NavOption.DOCUMENTS}`)}>
                  Documents 
                </Link>
                {' '} section for more on what is needed).
              </ListItem>
            </OrderedList>
          </>
        )
    }
  }

  return (
    <>
      <Box pl={10} pr={10}>
        {
          isHidden ? (
            <Link color="brand.100" fontSize={14} onClick={() => setIsHidden(false)}>Show next steps</Link>
          ) : (
            <Box pt={5} pb={10} pl={10} pr={10} borderRadius={10} bg="white">
              <HStack>
                <Spacer />
                <Link color="brand.100" fontSize={12} onClick={() => setIsHidden(true)}>Hide</Link>
              </HStack>
              { getWhatsNextInstructions() }
            </Box>
          )
        }
      </Box>
      {
        offerNumber != null && (
          <AcceptOfferModal
            isOpen={isAcceptOfferModalOpen}
            onClose={handleAcceptOfferModalClose}
            offerNumber={offerNumber}
            handleAcceptOfferClick={handleConfirmAcceptOffer}
          />
        )
      }
    </>
  )
}
