import { Spacer, Stack, Text, VStack } from "@chakra-ui/react"
import { DealItem } from "./ui"
import { Deal } from "../../types"

interface Props {
  deals: Deal[]
}

export const DealsComponent = ({ deals }: Props) => {
  return (
    <Stack pl={10} pr={10}>
      <Text
        fontSize="lg"
        color="black"
        fontWeight="extrabold"
        pb={2}
        pt={5}
        textAlign="left"
      >
        Your Deals
      </Text>
      {
        deals.length === 0 ? (
          <VStack h="100vh">
            <Spacer />
            <Text fontSize="2xl" fontWeight="bold" textAlign="center" pl={20} pr={20}>
              {
                `You currently don't have any deals live on the portal right now`
              }
            </Text>
            <Spacer />
          </VStack>
        ) : (
          <Stack pb={10}>
            {
              deals.map(deal => {
                return (
                  <DealItem deal={deal} />
                )
              })
            }
          </Stack>
        )
      }
    </Stack>
  )
}
