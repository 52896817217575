import { AxiosInstance } from "axios"
import { useEffect, useState } from "react"
import { Box, Grid, Stack, Text, VStack } from "@chakra-ui/react"
import { Me, Resource } from "../../types"
import { ResourceGridItem } from "./ui"
import { CopyrightFooter, EquityPartnerNavigationBar, NavigationBar } from "../Main/ui"
import { getUser, useDevice } from "../../hooks"
import { UserType, } from "../../types"
import { useNavigate } from "react-router-dom"
import { ResourcesComponent } from "./ResourcesComponent"

interface Props {
  axios: AxiosInstance
}

export const ResourcesPage = ({ axios }: Props) => {
  const { isMobile } = useDevice()
  const navigate = useNavigate()
  const [resources, setResources] = useState<Resource[]>([])
  const [user, setUser] = useState<Me | null>(null)
  
  useEffect(() => {
    const fetchResources = async () => {
      const response = await axios.post(`/resources/all`)
      const { data } = response
      if (data && data.success) {
        const _resources: Resource[] = data.resources
        setResources(_resources)
      }
    }
    const fetchUser = async () => {
        const user = await getUser()
        setUser(user)
    }
    fetchUser()
    fetchResources()
  }, [])

  const getNav = () => {
    if(user){
      if (user.type === UserType.DEAL_MANAGER) {
        return <EquityPartnerNavigationBar />
      } else if (user.type === UserType.CLIENT) {
        if (isMobile) {
          return <NavigationBar menu={false} />
        }
        return <NavigationBar/>
      }
    }
    else{
      navigate(`/login`)
    }
  } 
  
  
  return (
    <Stack h="100%">
      { getNav() }
      <VStack h="100vh" overflowY="scroll" p={0}>
      <ResourcesComponent resources={resources} />
      {/* <VStack h="100vh" overflowY="scroll" p={10}>
        <Text pt={isMobile?('30px'):("0")} fontSize="2xl" fontWeight="bold">
          Helpful Resources
        </Text>
        <Grid templateColumns={isMobile ? ('repeat(2, 1fr)'):('repeat(3, 1fr)')} gap={isMobile?(2):(6)} p={isMobile?(5):(10)}>
          {
            resources.map(resource => {
              return (
                <ResourceGridItem resource={resource} />
              )
            })
          }
        </Grid>
         */}
        <Box p={20}>
          <CopyrightFooter />
        </Box>
      </VStack> 
    </Stack>
  )
}
