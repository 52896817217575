
import { useEffect, useState } from "react"
import { Box, HStack, Link, Stack, Text } from "@chakra-ui/react"
import { DollarInput } from "../../Main/ui"
import { format, parse } from "../../../utils"
import { EquityType, Offer } from "../../../types"
import { HypotheticalExitIllustrationChart } from "./HypotheticalExitIllustrationChart"
import { OfferDetailsModal } from "./OfferDetailsModal"
import { useDevice } from "../../../hooks"

interface Props {
  offer: Offer
  pp: number
  lp: number
}

export const HypotheticalExitIllustrationComponent = ({ offer, pp, lp }: Props) => {
  const {isMobile} = useDevice()

  const [sharePrice, setSharePrice] = useState(offer.hypotheticalSharePrice)
  const [totalShares, setTotalShares] = useState(0)
  const [grossProceeds, setGrossProceeds] = useState(0)
  const [netFinalDistribution, setNetFinalDistribution] = useState(0)
  const [esoGrossProceeds, setEsoGrossProceeds] = useState(0)
  const [netTotalProceeds, setNetTotalProceeds] = useState(0)
  const [netProceeds, setNetProceeds] = useState(0)
  const [isOfferDetailModalOpen, setIsOfferDetailModalOpen] = useState(false)

  const handleSharePriceChange = (value: string) => {
    setSharePrice(parseFloat(parse(value)))
  }

  useEffect(() => {
    const { equities, exerciseCost, desiredLiquidity } = offer
    const totalShares = equities.reduce((partialSum, equity) => partialSum + equity.amount, 0)
    setTotalShares(totalShares)
    const grossProceeds = totalShares * sharePrice
    setGrossProceeds(grossProceeds)
    const esoParticipation = grossProceeds * pp
    const distribution = grossProceeds - esoParticipation - lp
    const _esoGrossProceeds = grossProceeds * pp + lp
    setEsoGrossProceeds(_esoGrossProceeds > grossProceeds ? grossProceeds : _esoGrossProceeds)
    const netDistribution = distribution > 0 ? distribution : 0
    let nsoTax = 0
    for (let i = 0; i < equities.length; i++) {
      const equity = equities[i]
      const { type, tax } = equity
      if (type === EquityType.NSO) {
        nsoTax += tax
      }
    }

    console.log(exerciseCost)
    console.log(desiredLiquidity)
    console.log(netDistribution)
    console.log(nsoTax)

    const _netProceeds = desiredLiquidity + netDistribution + nsoTax
    console.log(_netProceeds)
    setNetFinalDistribution(netDistribution)
    setNetTotalProceeds(grossProceeds)
    setNetProceeds(_netProceeds)
  }, [pp, lp, offer, sharePrice, setNetFinalDistribution, setEsoGrossProceeds, setNetTotalProceeds, setNetProceeds])
  
  return (
    <>
    {
      isMobile ? (
        <Stack pl={0} pt={10}>
          <Text fontSize="xl" pl={10}>
            Hypothetical Exit Illustration
          </Text>
          <Stack w="100%" pt={2}>
            <Stack w="100%" pl={10} pr={10}>
              <Text fontSize={15} textAlign="left" >
                Sample Exit Price
              </Text>
              <DollarInput value={format(String(sharePrice))} setValue={handleSharePriceChange} />
              <Text pt={2}>
                Your Proceeds: 
              </Text>
              <Text
                fontSize="3xl"
                fontWeight="extrabold"
              >
                { `$${netFinalDistribution.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` }
              </Text>
              <HStack>
              <Text>
                {`Total Proceeds: $${netTotalProceeds.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
              </Text>
              <Text>
                { `ESO Proceeds: $${esoGrossProceeds.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` }
              </Text>
              </HStack>
            </Stack>
            <Box p={0}>
              <HypotheticalExitIllustrationChart clientReturn={netProceeds} esoReturn={esoGrossProceeds} />
            </Box>
            <Text as={Link} color="brand.300" pl={10} fontSize={12} onClick={() => setIsOfferDetailModalOpen(true)}>Click here for the breakdown</Text>
          </Stack>
          <OfferDetailsModal
            isOpen={isOfferDetailModalOpen}
            grossProceeds={grossProceeds}
            totalShares={totalShares}
            sharePrice={sharePrice}
            pp={offer.pp}
            lp={offer.lp}
            netDistribution={netFinalDistribution}
            esoGrossProceeds={esoGrossProceeds}
            netProceeds={netProceeds}
            onClose={() => setIsOfferDetailModalOpen(false)}
          />
        </Stack>
      ) : (
        <Stack pl={10} pt={10}>
          <Text fontSize="xl">
            Hypothetical Exit Illustration
          </Text>
          <HStack w="100%" pt={10}>
            <Stack w="40%">
              <Text fontSize={15} textAlign="left">
                Sample Exit Price
              </Text>
              <DollarInput value={format(String(sharePrice))} setValue={handleSharePriceChange} />
              <Text pt={10}>
                Your Proceeds: 
              </Text>
              <Text
                fontSize="3xl"
                fontWeight="extrabold"
              >
                { `$${netFinalDistribution.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` }
              </Text>
              <Text>
                {`Total Proceeds: $${netTotalProceeds.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}
              </Text>
              <Text>
                { `ESO Proceeds: $${esoGrossProceeds.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}` }
              </Text>
              <Text as={Link} color="brand.300" pt={5} fontSize={12} onClick={() => setIsOfferDetailModalOpen(true)}>Click here for the breakdown</Text>
            </Stack>
            <Box pl={10} w="50%">
              <HypotheticalExitIllustrationChart clientReturn={netProceeds} esoReturn={esoGrossProceeds} />
            </Box>
          </HStack>
          <OfferDetailsModal
            isOpen={isOfferDetailModalOpen}
            grossProceeds={grossProceeds}
            totalShares={totalShares}
            sharePrice={sharePrice}
            pp={offer.pp}
            lp={offer.lp}
            netDistribution={netFinalDistribution}
            esoGrossProceeds={esoGrossProceeds}
            netProceeds={netProceeds}
            onClose={() => setIsOfferDetailModalOpen(false)}
          />
        </Stack>
      )
  }
  </>
  )
}