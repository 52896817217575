import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text
} from "@chakra-ui/react"

interface Props {
  isOpen: boolean
  offerNumber: number
  onClose: () => void
  handleAcceptOfferClick: (offerNumber: number) => void
}

export const AcceptOfferModal = ({ isOpen, offerNumber, onClose, handleAcceptOfferClick }: Props) => {
  return (
    <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="brand.100">
        <ModalHeader color="white">Confirm Offer Acceptance</ModalHeader>
        <ModalCloseButton color="white" />
        <ModalBody>
          <Box w="100%" p={5}>
            <Text color="white">
              { `Are you sure you want to accept Offer #${offerNumber}?` }
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" color="white" mr={3} onClick={onClose}>
            Close
          </Button>
          <Button variant="solid" bg="white" color="brand.100" onClick={() => handleAcceptOfferClick(offerNumber)}>
            Accept!
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}