import { AxiosInstance } from "axios"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import styled from "styled-components"
import {
  Client,
  Company,
  Deal,
  DealManager,
  NavOption,
  OfferStatus,
  Resource,
} from "../../types"
import { Box, HStack, Spacer, Stack, Text } from "@chakra-ui/react"
import { CopyrightFooter, DealManagerPanel, Navigation, NavigationBar, ReturnToPortfolioLink } from "./ui"
import { NoDealComponent, OfferComponent } from "../Offer"
import { TaxCalculationComponent } from "../Tax"
import { DocumentsComponent } from "../Documents"
import { ResourcesComponent } from "../Resources"
import { BackgroundCheckComponent } from "../Background"
import { getIsLoggedIn, useDevice } from "../../hooks"

const LeftPanelContainer = styled(Stack)``
LeftPanelContainer.defaultProps = {
  width: 'calc(100% / 5)',
  height: 'calc(100%)',
  position: 'fixed',
  left: 0,
  top: "60px",
  paddingTop: "20px",
  paddingBottom: "20px",
  // background: "#5856e7",
  background: '#fafbfb',
  overflowY: 'scroll',
  overflowX: 'hidden',
  borderRight: "1px solid #edebeb"
}

const LargeContainer = styled(Box)``
LargeContainer.defaultProps = {
  width: '80%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '20%',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

const MobileContainer = styled(Box)``
MobileContainer.defaultProps = {
  width: '100%',
  height: '100%',
  position: 'fixed',
  top: "60px",
  left: '0',
  overflowY: 'scroll',
  overflowX: 'hidden',
  paddingBottom: "50px",
  background: "white"
}

interface Props {
  currentNavOption: string
  axios: AxiosInstance
}

export const DashboardPage = ({ currentNavOption, axios }: Props) => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params
  const {isMobile} = useDevice()
  const [deal, setDeal] = useState<Deal | null>(null)
  const [company, setCompany] = useState<Company | null>(null)
  const [client, setClient] = useState<Client | null>(null)
  const [dealManager, setDealManager] = useState<DealManager | null>(null)
  const [resources, setResources] = useState<Resource[]>([])
  
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await axios.post(`/client/`)
        const { data } = response
        if (response.status === 200 && data?.success) {
          const _client: Client = data.client
          setClient(_client)
          const { deals } = _client
          if (deals.length) {
            if (id) {
              const _deal = deals.find(d => d.id === parseInt(id))
              if (_deal) {
                const { company, dealManager } = _deal
                setCompany(company)
                setDeal(_deal)
                setDealManager(dealManager)
              }
            }
          } else {
            const { dealManager } = _client
            if (dealManager) {
              setDealManager(dealManager)
            }
          }
          await fetchDocuments()
        } else {
          navigate(`/login`)
        }
      } catch(e) {
        navigate(`/login`)
      }
    }

    const fetchDocuments = async () => {
      const response = await axios.post(`/resources/all`)
      const { data } = response
      if (data && data.success) {
        const _resources: Resource[] = data.resources
        setResources(_resources)
      }
    }
    
    const isLoggedIn = getIsLoggedIn()
    if (isLoggedIn) {
      fetchClient()
    } else {
      navigate(`/login`)
    }
  }, [])

  const acceptOffer = async (offerNumber: number) => {
    if (deal?.stage === OfferStatus.OFFER_READY) {
      const response = await axios.post(`/deals/accept`, { dealId: deal.id, offerNumber })
      const { data } = response      
      if (data && data.success) {
        setDeal(data.deal)
      }
    }
  }

  const getMainContainerComponent = () => {
    if (currentNavOption === NavOption.CURRENT_OFFER) {
      if (deal && dealManager) {
        let offers = deal.offers
        if (deal.stage === OfferStatus.CONTRACT_REVIEW || deal.stage === OfferStatus.POST_CLOSING || deal.stage === OfferStatus.CLOSED || deal.stage === OfferStatus.LIQUIDITY) {
          offers = []
          for (let i = 0; i < deal.offers.length; i++) {
            const offer = deal.offers[i]
            if (offer.isAccepted === 1) {
              offers.push(offer)
              break
            }
          }
        }
        return (
          <OfferComponent
            axios={axios}
            company={deal.company}
            dealManager={dealManager}
            offers={offers}
            stage={deal.stage}
            acceptOffer={acceptOffer}
          />
        )
      } else {
        if (dealManager) {
          return (
            <NoDealComponent
              dealManager={dealManager}
            />
          )
        }
      }
    } else if (currentNavOption === NavOption.TAX_CALCULATION) {
      return (
        <TaxCalculationComponent />
      )
    } else if (currentNavOption === NavOption.DOCUMENTS) {
      if (company && dealManager) {
        return (
          <DocumentsComponent axios={axios} company={company} dealManager={dealManager} />
        )
      }
    } else if (currentNavOption === NavOption.RESOURCES) {
      return (
        <ResourcesComponent resources={resources} />
      )
    }
      // else if (currentNavOption === NavOption.BACKGROUND_CHECK) {
      // return (
      //   <BackgroundCheckComponent axios={axios} />
      // )
    // }
  }

  
  return (
    <>
    {
      isMobile ? (
      <>
        <NavigationBar dealManager={dealManager} />
        <Stack h='100vh'>
          <MobileContainer>
            { getMainContainerComponent() }
            <Box p={20}>
              <CopyrightFooter />
            </Box>
          </MobileContainer>
        </Stack>
      </>
      ) : (
      <>
      <NavigationBar />
      <LeftPanelContainer>
        <HStack pl={5} pr={5}>
          <Text fontSize="lg" fontWeight="bold" color="brand.300">
            { company ? company.name : "" }
          </Text>
          <Spacer />
        </HStack>
          <Navigation stage={deal?.stage} />
        <Spacer />
        {
          dealManager && (
            <DealManagerPanel dealManager={dealManager} />
          )
        }
      </LeftPanelContainer>
      <Stack h='100vh'>
        <LargeContainer>
          <ReturnToPortfolioLink />
          { getMainContainerComponent() }
          <Box p={20}>
            <CopyrightFooter />
          </Box>
        </LargeContainer>
      </Stack>
      </>
    )}
    </>
  )
}
