import { AxiosInstance } from "axios"
import { useState } from "react"
import { BiInfoCircle } from "react-icons/bi"
import { FaFileSignature } from "react-icons/fa"
import styled from "styled-components"
import {
  Box,
  Center,
  HStack,
  Icon,
  Image,
  Spacer,
  Stack,
  Text,
  Tooltip,
  useToast,
  VStack
} from "@chakra-ui/react"
import { UploadDocumentRow } from "./ui"
import { 
  INSTRUCTIONS_NDA,
  INSTRUCTIONS_BC,
  INSTRUCTIONS_OPTIONS_AND_SHARES,
  INSTRUCTIONS_OPTION_GRANT_DOCUMENTS,
  INSTRUCTIONS_STOCK_CERTIFICATE,
  INSTRUCTIONS_STOCK_OPTION_PLAN,
  get409aProofIntstructions
} from "./constants"
import { DocumentTypeDict, WHITE_LISTED_FILE_TYPES } from "../../constants"
import { Company, DealManager } from "../../types"
import { useDevice } from "../../hooks"

const LeftPanelContainer = styled(Box)``
LeftPanelContainer.defaultProps = {
  width: 'calc(100% / 5)',
  height: 'calc(100% - 50px)',
  position: 'fixed',
  left: 0,
  top: '70',
  overflowY: 'scroll',
  overflowX: 'hidden',
}

const MainContainer = styled(Box)``
MainContainer.defaultProps = {
  width: 'calc(100% * 0.75)',
  height: 'calc(100% - 50px)',
  position: 'fixed',
  top: 50,
  left: 'calc(100% / 5)',
  background: 'white',
  borderTopRadius: 10,
  overflowY: 'hidden',
  overflowX: 'hidden',
  paddingBottom: "50px",
}

interface Props {
  axios: AxiosInstance
  company: Company
  dealManager: DealManager
}

const EsoDocumentIcon = ({ instructions, name, requestForDocument }: { instructions: string, name: string, requestForDocument: (name: string) => void }) => {
  const { isMobile } = useDevice()
  return (
    <HStack key={name} cursor="pointer" onClick={() => requestForDocument(name)}>
      {
        !isMobile && (
          <Center w={55} h={55} bg="brand.300" borderRadius={50}>
            <Icon color="white" as={FaFileSignature} w={5} h={5} pl={1} />
          </Center>
      )}
      <Tooltip label={instructions} placement="bottom">
        <Stack>
          <HStack>
            <Text fontWeight="bold">
              { name }
            </Text>
            {
              !isMobile && (
                <Icon as={BiInfoCircle} h={4} w={4} color="brand.300" />
            )}
          </HStack>
          <Text fontSize={12}>
            Click to sign
          </Text>
        </Stack>
      </Tooltip>
    </HStack>
  )
}

const SendingEsoDocumentIcon = () => {
  return (
    <VStack w={100} h={100}>
      <Image src="images/degree.png" alt="Certificate" />
      <Text fontSize={12} textAlign="center">
        Sending...
      </Text>
    </VStack>
  )
}

export const DocumentsComponent = ({ axios, company, dealManager }: Props) => {
  const toast = useToast()

  const [isLoading, setIsLoading] = useState(false)

  const [fmvProofDocument, setFmvProofDocument] = useState<File | null>(null)
  const [optionsVestingProofDocument, setOptionsVestingProofDocument] = useState<File | null>(null)
  const [optionPlanDocument, setOptionPlanDocument] = useState<File | null>(null)
  const [optionGrantDocument, setOptionGrantDocument] = useState<File | null>(null)
  const [stockCertificateDocument, setStockCertificateDocument] = useState<File | null>(null)
  const [driversLicenseDocument, setDriversLicenseDocument] = useState<File | null>(null)
  const [passportDocument, setPassportDocument] = useState<File | null>(null)
  const [utilityBillDocument, setUtilityBillDocument] = useState<File | null>(null)

  const [fmvProofDocumentKey, setFmvProofDocumentKey] = useState<string>(Math.random().toString(36))
  const [optionsVestingProofDocumentKey, setOptionsVestingProofDocumentKey] = useState<string>(Math.random().toString(36))
  const [optionPlanDocumentKey, setOptionPlanDocumentKey] = useState<string>(Math.random().toString(36))
  const [optionGrantDocumentKey, setOptionGrantDocumentKey] = useState<string>(Math.random().toString(36))
  const [stockCertificateDocumentKey, setStockCertificateDocumentKey] = useState<string>(Math.random().toString(36))
  const [driversLicenseDocumentKey, setDriversLicenseDocumentKey] = useState<string>(Math.random().toString(36))
  const [passportDocumentKey, setPassportDocumentKey] = useState<string>(Math.random().toString(36))
  const [utilityBillDocumentKey, setUtilityBillDocumentKey] = useState<string>(Math.random().toString(36))

  const [isSendingNda, setIsSendingNda] = useState(false)
  const [isSendingBc, setIsSendingBc] = useState(false)

  const DOCUMENT_TYPE_NDA = "Mutual NDA"
  const DOCUMENT_TYPE_BACKGROUND_CHECK = "Background Check Permission Form"
  const INSTRUCTIONS_409A_PROOF = get409aProofIntstructions(company.name)

  const REQUIRED_OFFER_DOCS = 
  [
    {
      inputKey: fmvProofDocumentKey,
      type: DocumentTypeDict.fmvProofDocument,
      label: "409A Proof",
      description: "Equity portal screenshot or company email trail confirming th current fair market value.",
      instructions: INSTRUCTIONS_409A_PROOF,
      file: fmvProofDocument,
      icon: "409A",
    },
    {
      inputKey: optionsVestingProofDocumentKey,
      type: DocumentTypeDict.optionsVestingProofDocument,
      label: "Stock Options (or Shares) Vesting Proof",
      description: "Equity portal screenshot or grant documents confirming number of options, grant date, and strike price.",
      instructions: INSTRUCTIONS_OPTIONS_AND_SHARES,
      file: optionsVestingProofDocument,
      icon: "#",
    },
  ]

  const REQUIRED_CONTRACT_DOCS = 
  [
    {
      inputKey: optionPlanDocumentKey,
      type: DocumentTypeDict.optionPlanDocument,
      label: "Stock Option Plan",
      description: "Document governing stock option and share ownership at your company",
      instructions: INSTRUCTIONS_STOCK_OPTION_PLAN,
      file: optionPlanDocument,
      icon: "SOP",
    },
    {
      inputKey: optionGrantDocumentKey,
      type: DocumentTypeDict.optionGrantDocument,
      label: "Stock Option Grant Document (for each grant)",
      description: "Document governing each individual stock option grant",
      instructions: INSTRUCTIONS_OPTION_GRANT_DOCUMENTS,
      file: optionGrantDocument,
      icon: "SOG",
    },
    {
      inputKey: stockCertificateDocumentKey,
      type: DocumentTypeDict.stockCertificateDocument,
      label: "Stock Certificate Proof (Shares Only)",
      description: "Screenshot of your stock certificate",
      instructions: INSTRUCTIONS_STOCK_CERTIFICATE,
      file: stockCertificateDocument,
      icon: "CS",
    },
  ]

  const REQUIRED_CLOSING_DOCS = 
  [
    {
      inputKey: driversLicenseDocumentKey,
      type: DocumentTypeDict.driversLicenseDocument,
      label: "Driver's License",
      description: "Please provide a 1st valid photo ID, ideally a copy of your driver's license",
      instructions: "",
      file: driversLicenseDocument,
      icon: "ID",
    },
    {
      inputKey: passportDocumentKey,
      type: DocumentTypeDict.passportDocument,
      label: "Passport",
      description: "Please provide a 2nd valid photo ID, ideally a copy of your passport and/or green card if applicable",
      instructions: "",
      file: passportDocument,
      icon: "ID",
    },
    {
      inputKey: utilityBillDocumentKey,
      type: DocumentTypeDict.utilityBillDocument,
      description: "Please provide a utility bill from your current address IF the address of your driver's license does not match the address in our contact information.",
      label: "Utility Bill (optional)",
      instructions: "",
      file: utilityBillDocument,
      icon: "ID",
    },
  ]

  const clearFiles = () => {
    setFmvProofDocument(null)
    setOptionsVestingProofDocument(null)
    setOptionPlanDocument(null)
    setOptionGrantDocument(null)
    setStockCertificateDocument(null)
    setDriversLicenseDocument(null)
    setPassportDocument(null)
    setUtilityBillDocument(null)
    setFmvProofDocumentKey(Math.random().toString(36))
    setOptionsVestingProofDocumentKey(Math.random().toString(36))
    setOptionPlanDocumentKey(Math.random().toString(36))
    setOptionGrantDocumentKey(Math.random().toString(36))
    setStockCertificateDocumentKey(Math.random().toString(36))
    setDriversLicenseDocumentKey(Math.random().toString(36))
    setPassportDocumentKey(Math.random().toString(36))
    setUtilityBillDocumentKey(Math.random().toString(36))
  }

  const makeSuccessToast = (fileToUpload: File) => {
    toast({
      title: `Successfully uploaded ${fileToUpload.name}`,
      status: "success",
      duration: 3000,
      isClosable: true,
    })
  }

  const makeFailureToast = (fileToUpload: File) => {
    toast({
      title: `Oops something went wrong while uploading ${fileToUpload.name}. In the meantime, please email ${dealManager.email} your documents`,
      status: "error",
      duration: 3000,
      isClosable: true,
    })
  } 

  const uploadDocument = async (type: string) => {
    setIsLoading(true)
    let fileToUpload
    if (fmvProofDocument) {
      fileToUpload = fmvProofDocument
    } else if (optionsVestingProofDocument) {
      fileToUpload = optionsVestingProofDocument
    } else if (optionPlanDocument) {
      fileToUpload = optionPlanDocument
    } else if (optionGrantDocument) {
      fileToUpload = optionGrantDocument
    } else if (stockCertificateDocument) {
      fileToUpload = stockCertificateDocument
    } else if (driversLicenseDocument) {
      fileToUpload = driversLicenseDocument
    } else if (passportDocument) {
      fileToUpload = passportDocument
    } else if (utilityBillDocument) {
      fileToUpload = utilityBillDocument
    }

    if (fileToUpload) {
      if (!WHITE_LISTED_FILE_TYPES.includes(fileToUpload.type)) {
        toast({
          title: `${fileToUpload.type} is not a valid file type`,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      } else {
        // Using fetch here because I'm having trouble with axios for form data queries
        const formData = new FormData()
        formData.append("file", fileToUpload)
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL!}/documents/upload`, {
          method: 'POST',
          body: formData,
          credentials: "include"
        })
        
        const uploadResponse = await res.json()
        const { filePath } = uploadResponse
        if (filePath) {
          const response = await axios.post(`/documents/move`, { fileName: filePath, key: type, companyName: company.name })
          const { data } = response
          if (data && data.success) {
            makeSuccessToast(fileToUpload)
          } else {
            makeFailureToast(fileToUpload)
          }
        } else {
          makeFailureToast(fileToUpload)
        }
      }
      clearFiles()
    }
    setIsLoading(false)
  }

  const requestForDocument = async (documentType: string) => {
    if (documentType === DOCUMENT_TYPE_NDA) {
      setIsSendingNda(true)
      const response = await axios.post(`/documents/nda`)
      const { data } = response
      if (data && data.success) {
        toast({
          title: `NDA successfully sent to your email! If you don't see it, please contact your equity partner.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          title: `Oops something went wrong while requesting for an NDA. In the meantime, please email ${dealManager.email} to request an NDA`,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
      setIsSendingNda(false)
    } else if (documentType === DOCUMENT_TYPE_BACKGROUND_CHECK) {
      setIsSendingBc(true)
      const response = await axios.post(`/documents/bc`)
      const { data } = response
      if (data && data.success) {
        toast({
          title: `Background Check successfully sent to your email! If you don't see it, please contact your equity partner.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        })
      } else {
        toast({
          title: `Oops something went wrong while requesting for Background Check. In the meantime, please email ${dealManager.email} to request a Background Check`,
          status: "error",
          duration: 3000,
          isClosable: true,
        })
      }
      setIsSendingBc(false)
    }
  }

  const setDocument = (file: File | null, key: string) => {
    clearFiles()
    switch(key) {
      case fmvProofDocumentKey:
        setFmvProofDocument(file)
        break
      case optionsVestingProofDocumentKey:
        setOptionsVestingProofDocument(file)
        break
      case optionPlanDocumentKey:
        setOptionPlanDocument(file)
        break
      case optionGrantDocumentKey:
        setOptionGrantDocument(file)
        break
      case stockCertificateDocumentKey:
        setStockCertificateDocument(file)
        break
      case driversLicenseDocumentKey:
        setDriversLicenseDocument(file)
        break
      case passportDocumentKey:
        setPassportDocument(file)
        break
      default:
        break
    }
  }

  return (
    <Box pl={10} pr={10}>
      <Stack spacing={10} pt={10} overflowY="scroll">
        <Text fontSize="2xl" fontWeight="bold">
          My Documents
        </Text>
        {
          isSendingNda ? (
            <SendingEsoDocumentIcon />
          ) : (
            <EsoDocumentIcon instructions={INSTRUCTIONS_NDA} name={DOCUMENT_TYPE_NDA} requestForDocument={requestForDocument} />
          )
        }
        {
          isSendingBc ? (
            <SendingEsoDocumentIcon />
          ) : (
            <EsoDocumentIcon instructions={INSTRUCTIONS_BC} name={DOCUMENT_TYPE_BACKGROUND_CHECK} requestForDocument={requestForDocument} />
          )
        }
        <Text pt={10} fontSize="2xl" fontWeight="bold">
          Required Documents (Offer)
        </Text>
        {
          REQUIRED_OFFER_DOCS.map(document => {
            return (
              <>
                <UploadDocumentRow
                  inputKey={document.inputKey}
                  type={document.type}
                  label={document.label}
                  description={document.description}
                  instructions={document.instructions}
                  icon={document.icon}
                  isLoading={isLoading}
                  file={document.file}
                  setDocument={setDocument}
                  uploadDocument={uploadDocument}
                />
              </>
            )
          })
        }
        <Text pt={10} fontSize="2xl" fontWeight="bold">
          Required Documents (Contract)
        </Text>
        {
          REQUIRED_CONTRACT_DOCS.map(document => {
            return (
              <>
                <UploadDocumentRow
                  inputKey={document.inputKey}
                  type={document.type}
                  label={document.label}
                  description={document.description}
                  instructions={document.instructions}
                  icon={document.icon}
                  isLoading={isLoading}
                  file={document.file}
                  setDocument={setDocument}
                  uploadDocument={uploadDocument}
                />
              </>
            )
          })
        }
        <Text pt={10} fontSize="2xl" fontWeight="bold">
          Required Documents (Closing)
        </Text>
        {
          REQUIRED_CLOSING_DOCS.map(document => {
            return (
              <>
                <UploadDocumentRow
                  inputKey={document.inputKey}
                  type={document.type}
                  label={document.label}
                  description={document.description}
                  instructions={document.instructions}
                  icon={document.icon}
                  isLoading={isLoading}
                  file={document.file}
                  setDocument={setDocument}
                  uploadDocument={uploadDocument}
                />
              </>
            )
          })
        }
        <Spacer p={20}/>
      </Stack>
    </Box>
  )
}
