import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Text
} from "@chakra-ui/react"

interface Props {
  isOpen: boolean
  grossProceeds: number
  totalShares: number
  sharePrice: number
  pp: number
  lp: number
  netDistribution: number
  esoGrossProceeds: number
  netProceeds: number
  onClose: () => void
}

export const OfferDetailsModal = ({ isOpen, grossProceeds, totalShares, sharePrice, pp, lp, netDistribution, esoGrossProceeds, netProceeds, onClose }: Props) => {
  return (
    <Modal isOpen={isOpen} size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="white">
        <ModalHeader>Offer Distribution Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w="100%" p={5}>
            <Text pb={5}>
              { `Let's assume a hypothetical final share price of $${sharePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} as inputted above. \nThe following distributions would result:\n\n` }
            </Text>
            <Text>
              {`Gross Proceeds = $${grossProceeds.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} (${totalShares.toLocaleString()} @ $${sharePrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}/sh)`}
            </Text>
            <Text>
              {`ESO Participation = $${(grossProceeds * pp).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} (Gross Proceeds * ${(pp * 100).toFixed(2)}%)`}
            </Text>
            <Text pb={5}>
              {`Liquidation Preference = ${lp.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}\n`}
            </Text>
            <Text pb={5}>
              {`Your Net Final Distribution = $${netDistribution.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} (Gross Proceeds - ESO Participation - Liquidation Preference)\n`}
            </Text>
            <Text>
              {`Your Total Net Proceeds = $${netProceeds.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} (Upfront Cash - Exercise Cost + Your Net Final Distsribution)`}
            </Text>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button variant="outline" color="black" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}