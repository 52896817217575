import { useEffect, useState } from "react"
import { AiOutlineSortAscending,AiOutlineSortDescending } from "react-icons/ai";
import { BsChevronDown, BsSearch } from "react-icons/bs"
import { HStack, Stack, Input, InputGroup, InputRightAddon, InputRightElement } from '@chakra-ui/react'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  IconButton,
} from "@chakra-ui/react"
import { Client, Deal, OfferStatus } from "../../../types"
import { cleanseOfferStatusStr } from "../../../utils"
import { HiOutlineFilter } from "react-icons/hi";

interface Props {
  setSortOption: (sortOption: string) => void
  setSortOrder: (sortOrder: string) => void
  setSearchParam: (searchParam: string[]) => void
  setStage: (stage: string) => void
  isMobile: boolean
}

export const EquityPartnerDashboardSearchBar = ({ setSortOption, setSortOrder, setSearchParam, setStage, isMobile }: Props) => {
  const statusArray = ["All", OfferStatus.INTRO, OfferStatus.ESO_REVIEW,OfferStatus.OFFER_READY, OfferStatus.PRE_CLOSING, OfferStatus.CONTRACT_REVIEW, OfferStatus.POST_CLOSING,/* OfferStatus.FULLY_FUNDED,*/ OfferStatus.LIQUIDITY, OfferStatus.CLOSED]
  const [searchText, setSearchText] = useState<string>("")
  const [sortOption, _setSortOption] = useState<string>("Sort by");
  const [reverseOption, setReverseOption] = useState<boolean>(false);
  const [statusOption, setStatusOption] = useState<string>("All");
  const [filterOpen, setFilterOpen] = useState<boolean>(false)

  useEffect(() => {
    setSortOrder(reverseOption ? "desc" : "asc")
    setSortOption(sortOption)
    if(statusOption === "All"){
      setStage('')
    }
    else{
      setStage(statusOption)
    }
  }, [reverseOption, sortOption, statusOption])

  const updateSearchParam = () => {
    setSearchParam(searchText.split(" "))
  }

  return (
    <>
      {
      isMobile ? (
        <Stack pl={2} pr={2}>
          <HStack ml={5} mr={5}>
            <InputGroup>
              <Input
                color="black"
                placeholder="Search"
                value={searchText}
                onChange={(event) => setSearchText(event.target.value)}
                onKeyPress={event => {
                  if(event.key === 'Enter'){
                    updateSearchParam()
                  }
                }
              }
              />
              <InputRightElement >
                <IconButton size={'sm'} colorScheme="white" color="black" aria-label="Search" icon={<BsSearch />} onClick={() => updateSearchParam()} />
              </InputRightElement>
            </InputGroup>
            <IconButton aria-label="Filter" icon={<HiOutlineFilter />} onClick={() => setFilterOpen(!filterOpen)} />
          </HStack>
        {
        filterOpen && (
        <Stack>
          <HStack ml={5} mr={5}>
            <Menu>  
              <MenuButton as={Button} rightIcon={<BsChevronDown/>} w={"100%"}>
                {sortOption}
              </MenuButton>
              <MenuList>
                <MenuItem onClick={() => _setSortOption("First Name")}>First Name</MenuItem>
                <MenuItem onClick={() => _setSortOption("Last Name")}>Last Name</MenuItem>
                <MenuItem onClick={() => _setSortOption("Company")}>Company</MenuItem>
              </MenuList>
            </Menu>
            <Menu> 
            <MenuButton as={Button} rightIcon={<BsChevronDown/>} w={"100%"}>
              {cleanseOfferStatusStr(statusOption)}
            </MenuButton>
            <MenuList>
              {statusArray.map((status) => {
                  return (
                    <MenuItem onClick={() => setStatusOption(status)}>{cleanseOfferStatusStr(status)}</MenuItem>
                  )
                })
              }
            </MenuList>
          </Menu> 
            <IconButton
                aria-label = "Change Sort Direcetion" 
                icon={reverseOption ? <AiOutlineSortDescending /> : <AiOutlineSortAscending />}  
                onClick={() => setReverseOption(!reverseOption)} 
            />
          </HStack>
        </Stack>
        )
        }
      </Stack>
      ):(
        <HStack pl={10} pt={10}>
          <InputGroup w="30%">
            <Input
              color="black"
              placeholder="Search"
              value={searchText}
              onChange={(event) => setSearchText(event.target.value)}
              onKeyPress={event => {
                if(event.key === 'Enter'){
                  updateSearchParam()
                }
              }
            }
            />
            <InputRightElement>
              <IconButton size={'sm'} colorScheme="white" color="black" aria-label="Search" icon={<BsSearch />} onClick={() => updateSearchParam()} />
            </InputRightElement>
          </InputGroup>
          <Menu>  
            <MenuButton as={Button} rightIcon={<BsChevronDown/>}>
              {sortOption}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => _setSortOption("First Name")}>First Name</MenuItem>
              <MenuItem onClick={() => _setSortOption("Last Name")}>Last Name</MenuItem>
              <MenuItem onClick={() => _setSortOption("Company")}>Company</MenuItem>
            </MenuList>
          </Menu>
          <Menu>  
            <MenuButton as={Button} rightIcon={<BsChevronDown/>}>
              {cleanseOfferStatusStr(statusOption)}
            </MenuButton>
            <MenuList>
              {statusArray.map((status) => {
                  return (
                    <MenuItem onClick={() => setStatusOption(status)}>{cleanseOfferStatusStr(status)}</MenuItem>
                  )
                })
              }
            </MenuList>
          </Menu> 
            <IconButton
              aria-label = "Change Sort Direcetion" 
              icon={reverseOption ? <AiOutlineSortDescending /> : <AiOutlineSortAscending />}  
              onClick={() => setReverseOption(!reverseOption)} />
        </HStack>
        )
      }
    </>
  )
}
