import { AxiosInstance } from "axios"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Divider, HStack, Spacer, Text, Stack, Center, VStack, Box } from "@chakra-ui/react"
import { getIsLoggedIn, useDevice } from "../../hooks"
import { Client } from "../../types"
import { CopyrightFooter, DealRow, NavigationBar, NoDealsComponent } from "./ui"

interface Props {
  axios: AxiosInstance
}

export const PortfolioPage = ({ axios }: Props) => {
  const navigate = useNavigate()
  const [client, setClient] = useState<Client | null>(null)
  const {isMobile} = useDevice()

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await axios.post(`/client/`)
        const { data } = response
        if (response.status === 200 && data?.success) {
          const _client: Client = data.client
          setClient(_client)
        } else {
          navigate(`/login`)
        }
      } catch(e) {
        console.log(e)
        navigate(`/login`)
      }
    }
    
    const isLoggedIn = getIsLoggedIn()
    if (isLoggedIn) {
      fetchClient()
    } else {
      navigate(`/login`)
    }
  }, [])

  return (
  <>
    {
    isMobile ? (
      <Box h='100vh' bg="white" overflowY="scroll">
      <NavigationBar menu={false}/>
      <VStack w="100%">
        {
          client && (
            <Stack pt={"60px"} w="90%">
              <HStack>
                <Text pt={2} pb={2} fontSize="3xl" fontWeight="bold">
                  {`${client.firstName} ${client.lastName}'s Portfolio`}
                </Text>
              </HStack>
              <Divider />
              <Text pt={2} pb={2} fontSize="xl">
                Deals
              </Text>
              {
                client.deals.length > 0 ? (
                  <>
                    {
                      client.deals.map(deal => {
                        return (
                          <DealRow deal={deal} />
                        )
                      })
                    }
                  </>
                ) : (
                  <NoDealsComponent dealManager={client.dealManager} />
                )
              }
              <CopyrightFooter />
            </Stack>
          )
        }
      </VStack>
    </Box>

    ) : (

    <Box h='100vh' bg="white" overflowY="scroll">
      <NavigationBar />
      <VStack w="100%">
        {
          client && (
            <Stack pb={10} w="70%">
              <HStack>
                <Text pt={10} pb={5} fontSize="3xl" fontWeight="bold">
                  {`${client.firstName} ${client.lastName}'s Portfolio`}
                </Text>
                <Spacer />
              </HStack>
              <Divider />
              <Text pt={5} pb={5} fontSize="xl">
                Deals
              </Text>
              {
                client.deals.length > 0 ? (
                  <>
                    {
                      client.deals.map(deal => {
                        return (
                          <DealRow deal={deal} />
                        )
                      })
                    }
                  </>
                ) : (
                  <NoDealsComponent dealManager={client.dealManager} />
                )
              }
              <CopyrightFooter />
            </Stack>
          )
        }
      </VStack>
    </Box>
    )
    }
  </>
  )
}