import { Button, Text, VStack } from "@chakra-ui/react"
import { OfferStatusBar } from "./ui"
import { DealManager, OfferStatus } from "../../types"
import { openInNewTab } from "../../utils"

interface Props {
  dealManager: DealManager
}

export const NoDealComponent = ({ dealManager }: Props) => {
  return (
    <VStack>
      <OfferStatusBar status={OfferStatus.INTRO} />
      <Text pt={10} pb={5} fontSize="3xl" fontWeight="extrabold">
        Welcome to the ESO Portal
      </Text>
      <Text pb={10} fontSize="xl">
        Get started by scheduling a call with your equity partner
      </Text>
      <Button
        colorScheme="brand"
        variant="solid"
        onClick={() => openInNewTab(dealManager.calendly)}
      >
        Schedule a call
      </Button>
    </VStack>
  )
}