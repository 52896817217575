import { Center, HStack, Icon, Stack, Text } from "@chakra-ui/react"
import { IconType } from "react-icons/lib"
import { useDevice } from "../../../hooks"

interface Props {
  label: string
  iconType: IconType
  value: string
  details: string
}

export const OfferDetailsItem = ({ label, value, iconType, details }: Props) => {
  const { isMobile } = useDevice()
  return (
    <>
    {
      isMobile ? (
        <HStack pt={5}>
          <Stack pl={1} pr={1}>
            <HStack>
              <Text>
                { label }
              </Text>
              <Text fontSize="2xl">
                { value }
              </Text>
            </HStack>
            <Text fontSize="sm" color="gray">
              { details } 
            </Text>
          </Stack>
        </HStack>
      ) : (
        <HStack pt={10}>
          <Center w={55} h={55} bg="brand.300" borderRadius={50}>
            <Icon color="white" as={iconType} w={3} h={3} />
          </Center>
          <Stack pl={5}>
            <HStack>
              <Text>
                { label }
              </Text>
              <Text fontSize="2xl">
                { value }
              </Text>
            </HStack>
            <Text fontSize="sm" color="gray">
              { details } 
            </Text>
          </Stack>
        </HStack>
      )
    }
    </>
  )
}