import {
  Button,
  HStack,
  Spacer,
  Stack,
  Text,
  VStack
} from "@chakra-ui/react"
import { BsPlusLg } from "react-icons/bs"
import { TaxCalculationRow } from "../ui"
import { StockOptionType, TaxCalculation } from "../../../types"
import { useDevice } from "../../../hooks"

interface Props {
  taxCalculations: TaxCalculation[]
  setTaxCalculations: (taxCalculations: TaxCalculation[]) => any
}

export const OptionsInfoComponent = ({ taxCalculations, setTaxCalculations }: Props) => {
  const { isMobile } = useDevice()
  const setAmount = (index: number, amount: number) => {
    const _taxCalculations = [...taxCalculations]
    const taxCalculation = _taxCalculations[index]
    taxCalculation.amount = amount
    setTaxCalculations(_taxCalculations)
  }

  const setStrikePrice = (index: number, strikePrice: number) => {
    const _taxCalculations = [...taxCalculations]
    const taxCalculation = _taxCalculations[index]
    taxCalculation.strikePrice = strikePrice
    setTaxCalculations(_taxCalculations)
  }

  const setStockOptionType = (index: number, type: string) => {
    const _taxCalculations = [...taxCalculations]
    const taxCalculation = _taxCalculations[index]
    let stockOptionType = StockOptionType.ISO
    if (type.toLowerCase() === StockOptionType.NSO) stockOptionType = StockOptionType.NSO
    taxCalculation.type = stockOptionType
    setTaxCalculations(_taxCalculations)
  }

  const addNewTaxCalculation = () => {
    const _taxCalculations = [...taxCalculations]
    _taxCalculations.push({ amount: 1, strikePrice: 0.01, type: StockOptionType.ISO })
    setTaxCalculations(_taxCalculations)
  }

  const deleteTaxCalculation = (index: number) => {
    if (taxCalculations.length > 1) {
      const _taxCalculations = [...taxCalculations]
      _taxCalculations.splice(index, 1)
      setTaxCalculations(_taxCalculations)
    }
  }

  return (
    <>
    {
      isMobile ? (
        <Stack w="100%" borderTopRadius={2} pb={2}>
          <Text fontSize="large" fontWeight="bold">
            Options Info
          </Text>
          <Text fontSize="sm" color="gray.500" pb={2}>
            Fill out your exercise information below
          </Text>
          <VStack spacing={2}>
            {
              taxCalculations.map((taxCalculation, index) => {
                return (
                  <Stack>
                    <Text fontSize={20} fontWeight="bold">
                        { `Grant ${index + 1}` }
                    </Text>
                      <TaxCalculationRow
                        key={index}
                        index={index}
                        taxCalculation={taxCalculation}
                        isDeletable={taxCalculations.length > 1}
                        setAmount={setAmount}
                        setStrikePrice={setStrikePrice}
                        setStockOptionType={setStockOptionType}
                        deleteTaxCalculation={deleteTaxCalculation}
                      />
                  </Stack>
                  
                )
              })
            }
          </VStack>
          <HStack pt={10}>
            <Spacer />
            <Button
              color='brand.300'
              variant={"outline"}
              leftIcon={<BsPlusLg />}
              p={5}
              onClick={() => addNewTaxCalculation()}
            >
              Add another grant
            </Button>
            <Spacer />
          </HStack>
        </Stack>
      ) : (
        <Stack w="100%" borderTopRadius={10} pb={10}>
          <Text fontSize="large" fontWeight="bold">
            Options Info
          </Text>
          <Text fontSize="sm" color="gray.500" pb={10}>
            Fill out your exercise information below
          </Text>
          <VStack spacing={10}>
            {
              taxCalculations.map((taxCalculation, index) => {
                return (
                  <HStack>
                    <Spacer />
                    <Text fontSize={20} fontWeight="bold" pr={10}>
                      { `Grant ${index + 1}` }
                    </Text>
                    <TaxCalculationRow
                      key={index}
                      index={index}
                      taxCalculation={taxCalculation}
                      isDeletable={taxCalculations.length > 1}
                      setAmount={setAmount}
                      setStrikePrice={setStrikePrice}
                      setStockOptionType={setStockOptionType}
                      deleteTaxCalculation={deleteTaxCalculation}
                    />
                    <Spacer />
                  </HStack>
                )
              })
            }
          </VStack>
          <HStack pt={10}>
            <Spacer />
            <Button
              color='brand.300'
              variant={"outline"}
              leftIcon={<BsPlusLg />}
              p={5}
              onClick={() => addNewTaxCalculation()}
            >
              Add another grant
            </Button>
            <Spacer />
          </HStack>
        </Stack>
      )
    }
    </>
  )
}
// Add Tax Primer PDF, CPA Referral PDF only when Offer is sent and NDA is signed.