import { useNavigate } from "react-router-dom"
import { Box, Stack, Text } from "@chakra-ui/react"
import { Deal } from "../../../types"
import { cleanseOfferStatusStr } from "../../../utils"

interface Props {
  deal: Deal
}

export const DealItem = ({ deal }: Props) => {
  const navigate = useNavigate()

  return (
    <Box w="100%" key={deal.id} p={5} bg="white" borderWidth={1} borderColor={"gray.200"} borderRadius={10} cursor="pointer" onClick={() => navigate(`/client/${deal.id}`)}>
      <Stack>
        <Text fontSize="md" fontWeight="bold">
          { `${deal.client.firstName} ${deal.client.lastName}: ` }
        </Text>
        <Text fontSize="md">
          { `${deal.company.name}` }
        </Text>
        <Text fontSize="md">
          { `${cleanseOfferStatusStr(deal.stage)}` }
        </Text>
      </Stack>
    </Box>
  )
}
