import { BiInfoCircle } from "react-icons/bi"
import { Box, Button, Center, HStack, Icon, List, ListItem, Spacer, Stack, Text, Tooltip } from "@chakra-ui/react"
import { INSTRUCTIONS_ID_START, INSTRUCTIONS_ID_MID, INSTRUCTIONS_ID_END } from "../constants"
import { DocumentTypeDict } from "../../../constants"
import { useDevice } from "../../../hooks"

interface Props {
  file: File | null
  inputKey: string
  instructions: string
  isLoading: boolean
  label: string
  description: string
  icon: string
  type: string
  setDocument: (file: File | null, key: string) => void
  uploadDocument: (key: string) => void
}

export const UploadDocumentRow = ({ inputKey, type, label, description, instructions, icon, isLoading, file, setDocument, uploadDocument }: Props) => {
  const idTooltipLabel = () => {
    return (
      <Stack>
        <Text>
          { INSTRUCTIONS_ID_START }
        </Text>
        <List>
          <ListItem>
            { INSTRUCTIONS_ID_MID }
          </ListItem>
          <ListItem>
            { INSTRUCTIONS_ID_END }
          </ListItem>
        </List>
      </Stack>
    )
  }
  const {isMobile} = useDevice()
  return (
    <Box pt={5}>
      <HStack pb={5}>
        {
          !isMobile && (
          <Center w={55} h={55} bg="brand.300" borderRadius={50}>
            <Text color="white" fontSize="lg">
              { icon }
            </Text>
          </Center>
          )
        }
        
        <Stack>
          <Tooltip label={type === DocumentTypeDict.driversLicenseDocument || type === DocumentTypeDict.passportDocument ? idTooltipLabel() : instructions} placement="bottom">
            <HStack>
              <Text textAlign="left" fontWeight="bold">
                { label }
              </Text>
              {
                !isMobile && (
                  <Icon as={BiInfoCircle} h={4} w={4} color="brand.300" />
                )
              }
            </HStack>
          </Tooltip>
          <Text textAlign="left" fontSize="sm" color="gray.500">
            { description }
          </Text>
        </Stack>
      </HStack>
      <Box>
        <input
          key={inputKey || ""}
          type="file"
          accept=".gif,.jpg,.jpeg,.png,.doc,.docx,.pdf"
          onChange={(e) => setDocument(e.target.files ? e.target.files[0] : null, inputKey) }
        />
        {
          file ? (
            <Button colorScheme='brand' w="200px" onClick={() => uploadDocument(type)} isLoading={isLoading}>
              Upload File
            </Button>
          ) : (
            <Spacer />
          )
        }
      </Box>
    </Box>
  )
}
