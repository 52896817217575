export const INSTRUCTIONS_OFFER_MID = "Please note that the above does not constitute a formal offer and only the signed contract between you and ESO will constitute a binding agreement. In that contract, you are required to stipulate having disclosed all materially adverse risk factors for which you are aware. Prior to the contract, we must also approve a quick background and credit check. The amounts set forth above are for example only and actual proceeds will vary. The ESO Fund does not provide legal, financial or tax advice and neither this email nor other communications with the Fund should be construed as such. Title to the stock will remain in your name and you will retain voting, information, and other privileges associated with stock ownership."
export const INSTRUCTIONS_OFFER_START = "Please let your ESO Equity Partner know within 5 days if you want to move forward with this transaction or if you have any questions."
export const INSTRUCTIONS_OFFER_END = [
  "Your profits above would be achieved without your having to risk your personal funds or leave them tied up. This is in addition to savings that may apply to you such as long-term capital gains, tax deferment, opportunity cost, capital-loss carry-forwards, etc.",
  "The actual calculation of those savings is quite complicated and ultimately depends on your specific financial situation. We would be happy to supply the relevant figures to your CPA so they can calculate it. If you do not have a licensed tax CPA, there are several listed in our tax considerations email who are already familiar with ESO deal structures.",
  "Please contact your ESO Equity Partner for the latest edition of our Tax Primer and CPA Referral List",
  "Also note that your NSO withholding tax is merely an estimate by your company. Your actual tax obligation is based on your total income including the imputed ordinary income from this exercise and your actual deductions for the entire tax year. You are advised to work with a professional tax advisor to estimate your actual tax needs and notify the company to adjust their withholding amounts accordingly."
]

export const getToolTipLabelForOffer = ({
  companyName,
  exerciseCost,
  desiredLiquidity,
  numShares
}: {
  companyName: string,
  exerciseCost: number,
  desiredLiquidity: number,
  numShares: number
} ) => {
  if (exerciseCost > 0 && desiredLiquidity > 0) {
    return `I'm pleased to let you know that the Employee Stock Option Fund is interested in offering you a non-recourse advance of $${(exerciseCost + desiredLiquidity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} on your ${numShares.toLocaleString()} shares of ${companyName} stock listed in the schedule below.
    We will provide $${exerciseCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} for the purpose of exercising your ${numShares.toLocaleString()} options. You will need to provide satisfactory proof of option exercise and stock ownership. Upon doing so, we will provide an additional $${desiredLiquidity.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}, which is for personal liquidity and can be used to address your estimated taxes arising from this transaction.
    `
  } else if (exerciseCost > 0 && desiredLiquidity === 0) {
    return `
    I'm pleased to let you know that the Employee Stock Option Fund is interested in offering you a non-recourse advance of $${exerciseCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} on your ${numShares.toLocaleString()} shares of ${companyName} stock listed in the schedule below.
    We will provide $${exerciseCost.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} for the purpose of exercising your ${numShares.toLocaleString()} options. Following the exercise, you will need to provide satisfactory proof of option exercise and stock ownership.
    `
  } else if (exerciseCost === 0 && desiredLiquidity > 0) {
    return `
    I'm pleased to let you know that the Employee Stock Option Fund is interested in offering you a non-recourse advance of $${desiredLiquidity.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} on your ${numShares.toLocaleString()} shares of ${companyName} stock listed in the schedule below.
    `
  }
}

export const getToolTipLabelForESOParticipation = ({
  pp,
  lp,
  exerciseCost,
  desiredLiquidity,
}: {
  pp: number,
  lp: number,
  exerciseCost: number,
  desiredLiquidity: number
}) => {
  return `In exchange for our total investment of $${(exerciseCost + desiredLiquidity).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}, you will grant us a Participation Percentage of ${(pp * 100).toFixed(2)}% of all proceeds arising from your shares plus a liquidation preference of $${lp.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')} from your remaining ${((1 - pp) * 100).toFixed(2)}% of the proceeds.`
}