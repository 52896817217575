import { Button, Text, VStack } from "@chakra-ui/react"
import { DealManager } from "../../../types"
import { openInNewTab } from "../../../utils"

interface Props {
  dealManager: DealManager
}

export const NoOfferLabel = ({ dealManager }: Props) => {
  return (
    <VStack pt={10} pb={10}>
      <Text fontSize="2xl" fontWeight="bold" textAlign="center" pl={20} pr={20}>
        {
          `You do not have an active transaction with ESO Fund.`
        }
      </Text>
      <Text pl={20} pr={20} pb={10} textAlign="center">
        { `Please reach out to ${dealManager.firstName} if you have any questions, we are always happy to take another look or be a resource on your equity journey.` }
      </Text>
      <Button
        bg="brand.100"
        color="white"
        variant="solid"
        onClick={() => openInNewTab(dealManager.calendly)}
      >
        { `Schedule a call with ${dealManager.firstName}` }
      </Button>
    </VStack>
  )
}
