import {
  Chart as ChartJS,
  registerables,
} from "chart.js"
import { useEffect, useState } from "react"
import { Bar } from "react-chartjs-2"
import { Box } from "@chakra-ui/react"

ChartJS.register(
  ...registerables
)

export const options = {
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false
    }
  }
}

interface Props {
  clientReturn: number
  esoReturn: number
}

export const HypotheticalExitIllustrationChart = ({ clientReturn, esoReturn }: Props) => {
  const [data, setData] = useState<any>(null)

  useEffect(() => {
    console.log(clientReturn)
    console.log(esoReturn)
    const data = {
      labels: ['Your Return', 'ESO Return'],
      datasets: [{
        label: 'My First Dataset',
        data: [clientReturn, esoReturn],
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(54, 162, 235, 0.2)'
        ],
        borderColor: [
          'rgb(75, 192, 192)',
          'rgb(54, 162, 235)'
        ],
        borderWidth: 1
      }]
    }
    setData(data)
  }, [clientReturn, esoReturn])

  return (
    <Box>
      {
        data && (
          <Box borderRadius={10} bg="white" p={5}>
            <Bar options={options} data={data} />
          </Box>
        )
      }
    </Box>
  )
}